.leftArrow:hover {
  content: url('../img/leftArrowActive.png');
}

.rightArrow:hover {
  content: url('../img/rightArrowActive.png');
}

.legLeftArrow:hover {
  content: url('../img/legendaryLeftArrowActive.png');
}

.legRightArrow:hover {
  content: url('../img/legendaryRightArrowActive.png');
}

.v1Button:hover {
  content: url('../img/v1ButtonActive.png');
}

.v2Button:hover {
  content: url('../img/v2ButtonActive.png');
}

.buyNow:hover {
  content: url('../img/buyNowActive.png');
}

.legBuyNow:hover {
  content: url('../img/legendaryBuyActive.png');
}

.armoryButton:hover{
  content: url('../img/armory-btn.png')
}

.customizerButton:hover{
  content: url('../img/customizer-on.png');
}

.resetButton:hover{
  content: url('../img/reset-on.png');
}

.bazaarButton:hover{
  content: url('../img/goToBazaarOn.png');
}

.mintButton:hover{
  content: url('../img/mint-on.png');
}

.chooseNFT:hover{
  content: url('../img/chooseNFT-on.png');
}

.resetTraits:hover{
  content: url('../img/resetTraits-on.png');
}

.mintBtn:hover{
  content: url('../img/mint-btn-on.png');
}

.traitBackBtn:hover{
  content: url('../img/back-to-traits-on.png');
}

.gradient-border {
  position: relative;
}

.gradient-border::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 2px;
  border-style: solid;
  border-color: #3bc5fd;
  border-radius: 8px; /* Add rounded edges */
  box-shadow: 0 0 8px #3bc5fd, /* Inner glow */
              0 0 24px #3bc5fd, /* Outer glow */
              0 0 24px #3bc5fd, /* Outermost glow */
              0 4px 6px rgba(0, 0, 0, 0.1); /* Add a shadow */
  -webkit-background-clip: border-box;
  -webkit-mask-image: linear-gradient(to top, #3bc5fd, transparent);
  mask-image: linear-gradient(to top, #3bc5fd, transparent);
}

.gradient-yellow {
  position: relative;
}

.gradient-yellow::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 2px;
  border-style: solid;
  border-color: #8E6730;
  border-radius: 8px; /* Add rounded edges */
  -webkit-background-clip: border-box;
  -webkit-mask-image: linear-gradient(to bottom, #8E6730, transparent);
  mask-image: linear-gradient(to bottom, #8E6730, transparent);
}

/* in your .css file */
@keyframes ellipsis {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  100% {
    content: "...";
  }
}

.ellipsis:after {
  content: "";
  animation: ellipsis 1s infinite;
}
